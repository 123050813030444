import React from "react";
import "../styles/Header.scss";
import { Link } from "react-router-dom";
import heroCar from "../assets/images/hero-car.png";
import { FaLocationDot, FaPhone, FaCrown } from "react-icons/fa6";

const Header = () => {
	return (
		<header className="header-container wrapper">
			<div className="header-l">
				<span className="crown-box">
					<FaCrown className="crown-icon" />
					<div className="line"></div>
				</span>
				<h1 className="header-heading">
					Samochody Dostawcze i Osobowe Najwyższej Jakości
				</h1>
				<p className="header-text">
					Profesjonalnie zadbamy o każde twoje potrzeby. Nasze samochody staną
					się łącznikiem twojego biznesu ze światem.
				</p>
				<div className="header-contact-container">
					<div className="header-contact-item">
						<FaLocationDot className="header-contact-icon" />
						<p className="header-contact-text">Wieliczka</p>
					</div>
					<div className="header-contact-item">
						<FaPhone className="header-contact-icon" />
						<p className="header-contact-text">789-766-077</p>
					</div>
				</div>

				<Link to="/allCars" className="header-btn">
					Nasza Flota
				</Link>
			</div>
			<div className="header-r">
				<img
					src={heroCar}
					alt="White Iveco delivery van with an open cab"
					loading="lazy"
					className="header-car-img"
				/>
			</div>
		</header>
	);
};

export default Header;
