import React from "react";
import "../styles/CookiesAlert.scss";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const CookiesAlert = () => {
	return (
		<CookieConsent
			location="bottom"
			buttonText="Rozumiem"
			enableDeclineButton
			declineButtonText="Odmawiam"
			expires={30}
			buttonStyle={{
				fontWeight: "bold",
				backgroundColor: "green",
				border: "none",
				color: "white",
			}}>
			<p className="cookies-alert-text">
				🍪 Witaj na naszej stronie! Chcielibyśmy poinformować Cię o korzystaniu
				z plików cookies w celu poprawy jakości usług świadczonych przez naszą
				stronę internetową. Pliki cookies przechowywane na Twoim urządzeniu
				pomagają w pełnym korzystaniu z naszej witryny. Dzięki nim możemy
				dostosowywać zawartość i oferować Ci lepsze wrażenia online. Pliki
				cookies, które używamy, są bezpieczne. Nie gromadzimy żadnych prywatnych
				informacji bez Twojej zgody. W każdej chwili możesz zarządzać
				ustawieniami plików cookie. Dalsze korzystanie z witryny oznacza
				akceptację plików cookie. Dziękujemy za zrozumienie!{" "}
				<Link to="/cookiesInfo" className="cookies-alert-more-info-text">
					[Więcej informacji]
				</Link>
			</p>
		</CookieConsent>
	);
};

export default CookiesAlert;
