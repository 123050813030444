import React from "react";
import "../styles/Contact.scss";
import { SiLionair } from "react-icons/si";
import { FaLocationDot, FaPhone, FaFacebook } from "react-icons/fa6";
import Footer from "./Footer";

const Contact = () => {
	return (
		<>
			<div className="contact-container wrapper">
				<div className="contact-l">
					<h2 className="section-heading">
						<span>Skontaktuj się</span> z Nami
					</h2>
					<div className="contact-text-box">
						<SiLionair className="contact-logo"></SiLionair>
						<p className="contact-text">
							<FaLocationDot className="contact-icon" /> Wieliczka
						</p>
						<p className="contact-text">
							<FaPhone className="contact-icon" /> +48 789 766 077
						</p>
						<p className="contact-text">bax145@wp.pl</p>
						<a
							href="https://www.facebook.com/marek.konrad.3192479"
							target="_blank"
							rel="noreferrer"
							className="contact-fb-icon">
							<FaFacebook />
						</a>
					</div>
				</div>
				<div className="contact-r">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20522.14440556833!2d20.02551021668939!3d49.987583769196085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471643c46ad82f87%3A0x1d826671fc740453!2sWieliczka!5e0!3m2!1spl!2spl!4v1703003786486!5m2!1spl!2spl"
						loading="lazy"
						title="Google Maps"
						className="contact-map"></iframe>
				</div>
			</div>

			<Footer></Footer>
		</>
	);
};

export default Contact;
