import React from "react";
import "../styles/Brands.scss";
import ford from "../assets/icons/ford.svg";
import fiat from "../assets/icons/fiat.svg";
import opel from "../assets/icons/opel.svg";
import mercedes from "../assets/icons/mercedes.svg";
import peugeot from "../assets/icons/peugeot.svg";
import renault from "../assets/icons/renault.svg";
import citroen from "../assets/icons/citroen.svg";

const Brands = () => {
	return (
		<section className="brands ">
			<div className="brands-container wrapper">
				<img src={opel} loading="lazy" alt="opel icon" className="brand-icon" />
				<img src={fiat} loading="lazy" alt="fiat icon" className="brand-icon" />
				<img src={ford} loading="lazy" alt="ford icon" className="brand-icon" />
				<img
					src={mercedes}
					loading="lazy"
					alt="mercedes icon"
					className="brand-icon"
				/>
				<img
					src={citroen}
					loading="lazy"
					alt="citroen icon"
					className="brand-icon"
				/>
				<img
					src={renault}
					loading="lazy"
					alt="renault icon"
					className="brand-icon"
				/>
				<img
					src={peugeot}
					loading="lazy"
					alt="peugeot icon"
					className="brand-icon"
				/>
			</div>
		</section>
	);
};

export default Brands;
