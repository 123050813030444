import React, { useState } from "react";
import "../styles/Nav.scss";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { HashLink } from "react-router-hash-link";
import { SiLionair } from "react-icons/si";
import { IoIosMenu, IoIosClose   } from "react-icons/io";

const Nav = ({ currentUser, setCurrentUser }) => {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	const handleOpenNav = () => {
		!open ? setOpen(true) : setOpen(false);
	};

	const logOut = () => {
		signOut(auth)
			.then(() => {
				navigate("/login");
			})
			.catch((error) => {
				console.error(error);
			});

		setCurrentUser(null);
		return toast.success("Wylogowano");
	};

	return (
		<>
			<div className="nav">
				<div className="nav-container wrapper">
					<HashLink to="/#" className="logo-container">
						<SiLionair className="logo" />
						<div className="logo-text-box">
							<p className="logo-text">Car</p>
							<p className="logo-text">Market</p>
						</div>
					</HashLink>
					<div className="nav-links-container">
						<Link to="/" className="nav-link">
							Start
						</Link>
						<Link to="/allCars" className="nav-link">
							Flota
						</Link>
						<Link to="/contact" className="nav-link">
							Kontakt
						</Link>
						{currentUser?.uid === process.env.REACT_APP_ADMIN_IP && (
							<Link to="/addCar" className="nav-link">
								Dodaj ogłoszenie
							</Link>
						)}
						{!currentUser ? (
							<Link to="/login" className="nav-link-btn nav-link">
								Logowanie
							</Link>
						) : (
							<button
								onClick={() => logOut()}
								className="nav-link-btn nav-link">
								Wyloguj się
							</button>
						)}
					</div>
					{!open ? (
						<IoIosMenu className="burger-btn" onClick={() => handleOpenNav()} />
					) : (
						<IoIosClose   className="burger-btn" onClick={() => handleOpenNav()} />
					)}
				</div>
			</div>
			<div className={!open ? "mobile-menu" : "mobile-menu open-nav"}>
				<Link
					to="/"
					className="mobile-menu-item"
					onClick={() => setOpen(false)}>
					Start
				</Link>
				<Link
					to="/allCars"
					className="mobile-menu-item"
					onClick={() => setOpen(false)}>
					Flota
				</Link>

				<Link
					to="/contact"
					className="mobile-menu-item"
					onClick={() => setOpen(false)}>
					Kontakt
				</Link>
				{currentUser?.uid === process.env.REACT_APP_ADMIN_IP && (
					<Link
						to="/addCar"
						className="mobile-menu-item"
						onClick={() => setOpen(false)}>
						<button>Dodaj ogłoszenie</button>
					</Link>
				)}
				{!currentUser ? (
					<Link to="/login" className="mobile-menu-item mobile-menu-login-btn">
						Zaloguj się
					</Link>
				) : (
					<p
						onClick={() => logOut()}
						className="mobile-menu-item mobile-menu-login-btn">
						Wyloguj się
					</p>
				)}
			</div>
		</>
	);
};

export default Nav;
